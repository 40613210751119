<template>
   <div>
      <div class="row">
         <div class="col-xxl-12">
            <div class="card card-custom gutter-b">
               <div class="card-header border-0 pt-5 pb-5">
                  <h3 class="card-title align-items-start justify-content-center flex-column">
                     <span class="card-label font-weight-bolder">{{ $t('_INTERN.ACTION.GENERAL.LIST_TITLE') }}</span>
                     <span class="text-muted mt-3 font-weight-bold font-size-sm">{{
                        $t('_INTERN.ACTION.GENERAL.LIST_DESC', {
                           number: actions.length
                        })
                     }}</span>
                  </h3>
               </div>
               <div class="card-body p-0">
                  <Skeleton3 v-if="loading" />
                  <ActionTable v-else-if="actions && actions.length > 0" :sortBy="sortBy" :stagiaire="true" />
                  <div v-else-if="!loading && actions && actions.length === 0" class="px-5">
                     <div class="alert alert-info text-center">
                        {{ $t('_INTERN.ACTION.GENERAL.NO_ACTION') }}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="row mt-auto">
         <div class="col-xxl-12">
            <div>
               <b-pagination-nav
                  v-if="actions.meta"
                  class="mb-0"
                  align="center"
                  size="lg"
                  use-router
                  v-model="currentPage"
                  :number-of-pages="actions.meta.last_page"
                  :link-gen="linkGen"
               >
               </b-pagination-nav>
            </div>
         </div>
      </div>
      <div class="row">
         <div class="col-xxl">
            <div class="card card-custom gutter-b">
               <div class="card-header border-0 pt-5 pb-5">
                  <h3 class="card-title align-items-start justify-content-center flex-column">
                     <span class="card-label font-weight-bolder">{{ $t('INTERN.ACTION.TITLE_ALT') }}</span>
                     <span class="text-muted mt-3 font-weight-bold font-size-sm">{{
                        $t('INTERN.ACTION.DESC', { number: externalFormation ? externalFormation.length : 0 })
                     }}</span>
                  </h3>
               </div>
               <div class="card-body p-0">
                  <Skeleton3 v-if="loadingExternal" />
                  <ExternalFormationsTable v-else-if="externalFormation && externalFormation.length > 0" :externalFormation="externalFormation" />
                  <div v-else-if="externalFormation && externalFormation.length === 0 && !loadingExternal" class="px-5">
                     <div class="alert alert-info text-center">
                        {{ $t('INTERN.ACTION.NO_ACTION') }}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import ExternalFormationsTable from '@/view/pages/intern/widget/ExternalFormationsTable';
import { mapGetters, mapState, mapMutations } from 'vuex';
import { SET_PAGETITLE } from '@/core/services/store/pagetitle.module';
import { SET_TOOLBAR, RESET_TOOLBAR } from '@/core/services/store/config.module.js';
import { LIST_INTERN_ACTIONS } from '@/core/services/store/api-orion/action';
import ActionTable from '@/view/pages/action/widget/Table';
import Skeleton3 from '@/view/content/skeleton/Skeleton3';
import { GET_SOURCE_EXT } from '@/core/services/store/api/tableSource';
import { GET_FORMATION_USER } from '@/core/services/store/api/formation';
import moment from 'moment/moment';

export default {
   data() {
      return {
         loading: true,
         sortBy: 'start',
         currentPage: this.$route.query.page ? this.$route.query.page : 1,
         loadingExternal: true,
         externalFormation: null
      };
   },
   async mounted() {
      this.resetError;
      this.setToolbar();
      await this.getActions();
      this.setPageTitle();
      setTimeout(() => {
         this.loading = false;
      }, 200);
   },
   methods: {
      formatDate(value) {
         if (value) {
            return moment(String(value)).format('DD/MM/YYYY');
         }
      },
      view(diplome) {
         window.open(diplome.url);
      },
      setPageTitle() {
         this.$store.dispatch(SET_PAGETITLE, this.$t('_INTERN.ACTION.GENERAL.TITLE'));
      },
      async getActions() {
         const params = {
            tablename: 'users',
            tableid: this.currentUser.id
         };
         await this.$store.dispatch(GET_SOURCE_EXT, { params }).then(async (result) => {
            await this.$store.dispatch(LIST_INTERN_ACTIONS, { id: result.extid }).catch((error) => {
               if (error.status === 404) {
                  this.$router.push({ name: '404' });
               }
            });
            await this.$store
               .dispatch(GET_FORMATION_USER, { user_id: this.currentUser.id })
               .then((response) => {
                  this.externalFormation = [];
                  this.loadingExternal = false;
                  this.externalFormation = response;
               })
               .catch((error) => {
                  this.loadingExternal = false;
                  if (error.status === 404) {
                     this.$router.push({ name: '404' });
                  }
               });
         });
      },
      setToolbar() {
         this.$store.dispatch(SET_TOOLBAR, this.$t('TIP.ACTION.TABLE'));
      },
      linkGen(pageNum) {
         const query = {};
         Object.assign(query, this.$route.query);
         query.page = pageNum;
         return {
            name: this.$route.name,
            params: this.$route.params,
            query: query
         };
      }
   },
   beforeRouteLeave(to, from, next) {
      this.$store.dispatch(RESET_TOOLBAR).then(next);
   },
   components: {
      Skeleton3,
      ActionTable,
      ExternalFormationsTable
   },
   computed: {
      ...mapMutations({
         resetError: 'resetError'
      }),
      ...mapGetters({
         currentUser: 'currentUser',
         actions: 'listActions',
         intern: 'getIntern'
      }),
      ...mapState({
         error: (state) => state.action.error
      })
   }
};
</script>
